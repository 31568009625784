import { Controller } from "@hotwired/stimulus"
import { Howl, Howler } from "howler";
import axios from "axios";

export default class extends Controller {
  static targets = [
    "cover",
    "description",
    "elapsed",
    "episode",
    "nextButton",
    "playlist",
    "playlistMobile",
    "playlistButton",
    "podcast",
    "previousButton",
    "progress",
    "progressBar",
    "progressMobile",
    "speedButton",
    "total"
  ];

  initialize() {
    this.playlist = [];
    this.index = 0;
    this.isPlaying = false;

    let googleIdPlayer = document.querySelector("meta[name='google-analytics-id-player']").content;
    if(googleIdPlayer) {
      this.analyticsId = googleIdPlayer;
    }
  }
  
  connect() {
    if(this.isPlaying) {
      if(!document.body.classList.contains("player-loaded")) document.body.classList.add("player-loaded");
      document.querySelectorAll("a[href*='korii.slate.fr']").forEach(link => {
        link.target = "_blank";
      });
      document.querySelectorAll("a[href*='www.slate.fr']").forEach(link => {
        link.href = link.href.replace("https://www.slate.fr", "");
      });
    }
  }

  disconnect() {}

  changeSpeed() {
    var self = this;

    if(self.isPlaying) {
      var currentSpeed = self.speedButtonTarget.innerHTML;
      var newSpeed = 1;
      switch(currentSpeed) {
        case "x1":
          newSpeed = 1.25;
          break;
        case "x1.25":
          newSpeed = 1.5;
          break;
        case "x1.5":
          newSpeed = 0.5;
          break;
        case "x0.5":
          newSpeed = 0.75;
          break;
      }
      self.speedButtonTargets.forEach((speed) => {
        speed.innerHTML = `x${newSpeed}`;
      });
      self.playlist[self.index].howl.rate(newSpeed);
    }
  }

  formatTime(time) {
    var hours = Math.floor(time / 3600) || 0;
    var minutes = Math.floor((time - (hours * 3600)) / 60) || 0;
    var seconds = time - (hours * 3600) - (minutes * 60) || 0;

    var formattedTime = (minutes < 10 ? "0" : "") + minutes + ":" + 
                        (seconds < 10 ? "0" : "") + seconds;
    if(hours != 0) formattedTime = (hours < 10 ? "0" : "") + hours + ":" + formattedTime;
    return formattedTime;
  }

  getTCString() {
    var tcString = "";
    window.__tcfapi("getTCData", 2, function (tcData, success) {
      if (success) {
        tcString = tcData.tcString;
      }
    });
    return tcString;
  }

  goBackward() {
    var self = this;

    if(self.isPlaying) {
      var playingIndex = self.index;
      var playingSeek = self.playlist[self.index].howl.seek();
      self.playlist[self.index].howl.seek(playingSeek - 15);
    }
  }

  goForward() {
    var self = this;

    if(self.isPlaying) {
      var playingIndex = self.index;
      var playingSeek = self.playlist[self.index].howl.seek();
      self.playlist[self.index].howl.seek(playingSeek + 15);
    }
  }

  loadPlaylist(playlist, playlistIndex = 0) {
    var self = this;

    self.playlistTarget.innerHTML = "";
    self.playlistMobileTarget.querySelector(".list").innerHTML = "";

    if(!document.body.classList.contains("player-loaded")) document.body.classList.add("player-loaded", "player-init");

    if(playlist.length > 1) {
      self.playlistButtonTargets.forEach((playlistButton) => {
        playlistButton.removeAttribute("disabled");
      });
      var index = 0;
      playlist.forEach((episode) => {
        var newEpisodeContainer = document.createElement("div");
        newEpisodeContainer.classList.add("left");
        var newEpisodeContainerMobile = newEpisodeContainer.cloneNode(true);

        var newEpisode = document.createElement("div");
        newEpisode.classList.add("podcast", "h-100", "d-flex", "align-items-center");
        newEpisode.setAttribute("data-playlist-index", index);
        newEpisode.addEventListener("click", (event) => {
          const newIndex = parseInt(event.currentTarget.dataset.playlistIndex);
          self.skipTo(newIndex);
        });
        if(episode.cover) {
          var newEpisodeCover = document.createElement("img");
          newEpisodeCover.setAttribute("src", episode.cover);
          newEpisodeCover.classList.add("ms-lg-8", "me-4");
          newEpisode.appendChild(newEpisodeCover);
        }
        var newEpisodeTitle = document.createElement("div");
        newEpisodeTitle.classList.add("title");
        newEpisodeTitle.innerHTML = `<span>${episode.podcast}</span> - ${episode.episode}`;
        newEpisode.appendChild(newEpisodeTitle);
        newEpisodeContainer.appendChild(newEpisode);
        self.playlistTarget.appendChild(newEpisodeContainer);

        var newEpisodeMobile = newEpisode.cloneNode(true);
        var newEpisodePlayButton = document.createElement("button");
        newEpisodePlayButton.classList.add("button-play", "ms-auto");
        newEpisodePlayButton.innerHTML = '<i class="icon--play"></i>';
        newEpisodeMobile.appendChild(newEpisodePlayButton);
        var newEpisodePauseButton = document.createElement("button");
        newEpisodePauseButton.classList.add("button-pause", "ms-auto");
        newEpisodePauseButton.innerHTML = '<i class="icon--pause"></i>';
        newEpisodeMobile.appendChild(newEpisodePauseButton);
        newEpisodeContainerMobile.appendChild(newEpisodeMobile);
        self.playlistMobileTarget.querySelector(".list").appendChild(newEpisodeContainerMobile);

        index++;
      });
    } else {
      self.playlistButtonTargets.forEach((playlistButton) => {
        playlistButton.setAttribute("disabled", "disabled");
      });
    }

    self.playlist = playlist;
    self.skipTo(parseInt(playlistIndex));
  }

  pause() {
    var self = this;

    var sound = self.playlist[self.index].howl;

    sound.pause();
  }

  play(index) {
    var self = this;
    
    var sound;

    index = typeof index === "number" ? index : self.index;
    var data = self.playlist[index];

    if(!self.element.classList.contains("playing")) self.element.classList.add("loading");

    var tcString = self.getTCString();

    if (data.howl) {
      sound = data.howl;
    } else {
      var mp3_url = data.file;
      if(tcString !== '' && data.file.indexOf('?') !== -1) {
        mp3_url += '&gdpr_consent='+tcString;
      } else {
        mp3_url += '?gdpr_consent='+tcString;
      }
      sound = data.howl = new Howl({
        src: [mp3_url],
        html5: true,
        volume: 1,
        onplay: function() {
          self.totalTargets.forEach((total) => {
            total.innerHTML = self.formatTime(Math.round(sound.duration()));
          });

          self.progressTarget.style.display = "block";
          self.progressMobileTarget.style.display = "block";

          requestAnimationFrame(self.step.bind(self));
          self.isPlaying = true;
          self.element.classList.remove("loading");
          self.element.classList.add("playing");

          if(self.playlist.length > 1) {
            var playingPodcast = document.querySelectorAll(".podcast.playing");
            playingPodcast.forEach((podcast) => {
              podcast.classList.remove("playing");
            });
            self.playlistTarget.querySelector(`[data-playlist-index="${self.index}"]`).classList.add("playing");
            self.playlistMobileTarget.querySelector(`[data-playlist-index="${self.index}"]`).classList.add("playing");
          }

          if(index != self.index) {
            data.markers = {10: false, 20: false, 30: false, 40: false, 50:false, 60: false, 70: false, 80: false, 90: false};
            data.greatestMarker = 0;
          }

          var layerData = {
            "send_to": self.analyticsId,
            "event_category": "player",
            "transport_type": "beacon"
          };
          if(data.episode !== "") layerData["event_label"] = decodeURIComponent(data.episode);
          if(data.podcast !== "") layerData["podcastName"] = decodeURIComponent(data.podcast);
          gtag("event", "play", layerData);

          document.querySelectorAll("a[href*='korii.slate.fr']").forEach(link => {
            link.target = "_blank";
          });

          document.querySelectorAll("a[href*='www.slate.fr']").forEach(link => {
            link.href = link.href.replace("https://www.slate.fr", "");
          });
        },
        onload: function() {
          self.previousButtonTargets.forEach((previousButton) => {
            previousButton.removeAttribute("disabled");
            if(self.index == 0) previousButton.setAttribute("disabled", "disabled");
          });
          self.nextButtonTargets.forEach((nextButton) => {
            nextButton.removeAttribute("disabled");
            if(self.index == self.playlist.length-1) nextButton.setAttribute("disabled", "disabled");
          });
          self.element.querySelector(".sharing-tools").setAttribute("data-share-href", btoa(`https://www.slate.fr/${data.slug}`));

          data.markers = {10: false, 20: false, 30: false, 40: false, 50:false, 60: false, 70: false, 80: false, 90: false};
          data.greatestMarker = 0;
        },
        onend: function() {
          if(self.playlist.length > 1 && self.index < self.playlist.length-1) self.playNext();
          self.progressTarget.style.display = "none";
          self.progressMobileTarget.style.display = "none";
          self.sendCompletionEvent(100);
        },
        onpause: function() {
          self.element.classList.remove("playing");
          self.isPlaying = false;
        },
        onstop: function() {
          self.element.classList.remove("playing");
          self.isPlaying = false;
        },
        onseek: function() {
          requestAnimationFrame(self.step.bind(self));
        }
      });

      if(data.cover) {
        self.coverTargets.forEach((cover) => {
          cover.setAttribute("src", data.cover);
        });
      }
      self.episodeTargets.forEach((episode) => {
        episode.innerHTML = data.episode;
      });
      self.podcastTargets.forEach((podcast) => {
        podcast.innerHTML = data.podcast;
      });
      self.descriptionTarget.innerHTML = data.description;
    }

    if(sound.playing()) {
      sound.pause();
    } else {
      sound.play();
    }

    self.index = index;
  }

  playPrevious() {
    var self = this;

    if(self.index == 0) return;

    self.skipTo(self.index - 1);
  }

  playNext() {
    var self = this;

    if(self.index == self.playlist.length-1) return;

    self.skipTo(self.index + 1);
  }

  seek(event) {
    var self = this;

    var sound = self.playlist[self.index].howl;
    var progressRect;

    if(event.currentTarget.getAttribute("data-player-target") == "progress") {
      progressRect = self.progressTarget.getBoundingClientRect();
    } else {
      progressRect = self.progressMobileTarget.getBoundingClientRect();
    }
    const progressStart = progressRect.left;
    const clickPosition = event.clientX;
    const seekRatio = (clickPosition - progressStart) / progressRect.width;

    if(sound) sound.seek(sound.duration()*seekRatio);
  }

  sendCompletionEvent(percent = 0) {
    var self = this;
    var data = self.playlist[self.index];
    var marker = data.greatestMarker;
    if(percent > 0) marker = percent;
    var layerData = {
      "send_to": self.analyticsId,
      "event_category": "player",
      "event_label": marker,
      "transport_type": "beacon"
    };
    layerData["page_path"] = "/embed/"+data.id;
    if(data.episode !== "") layerData["page_title"] = decodeURIComponent(data.episode);
    if(data.podcast !== "") layerData["podcastName"] = decodeURIComponent(data.podcast);
    gtag("event", "completion", layerData);
  }

  shareMobile() {
    var self = this;

    const currentEpisode = self.playlist[self.index];

    if(currentEpisode.slug && navigator.share) {
      navigator.share({
        title: currentEpisode.title,
        url: `https://www.slate.fr/${currentEpisode.slug}`
      });
    }
  }

  skipTo(index) {
    var self = this;

    if(self.playlist[self.index].howl) self.playlist[self.index].howl.stop();

    self.play(index);
  }

  step() {
    var self = this;

    var sound = self.playlist[self.index].howl;

    var duration = sound.duration();
    var seek = sound.seek() || 0;
    self.elapsedTargets.forEach((elapsed) => {
      elapsed.innerHTML = self.formatTime(Math.round(seek));
    });
    
    var val = seek*100/duration;
    var translation = val+"%";
    self.progressBarTargets.forEach((progressBar) => {
      progressBar.style.width = translation;
    });

    if(self.isPlaying) requestAnimationFrame(self.step.bind(self));

    var pct = Math.floor(val);
    for(var i in self.playlist[self.index].markers) {
      if (pct >= i && i > self.playlist[self.index].greatestMarker) {
        self.playlist[self.index].greatestMarker = i;
        if(self.playlist[self.index].greatestMarker && !self.playlist[self.index].markers[i]) {
          self.playlist[self.index].markers[i] = true;
          self.sendCompletionEvent();
        }
      }
    }
  }

  stop() {
    var self = this;

    var sound = self.playlist[self.index].howl;

    sound.stop();
  }

  togglePlayer() {
    var self = this;

    if(document.body.classList.contains("player-loaded")) document.body.classList.toggle("player-opened");
  }

  togglePlayerDesktop() {
    if(document.body.classList.contains("player-init")) {
      document.body.classList.remove("player-init");
    } else {
      document.body.classList.toggle("player-opened");
    }
  }

  togglePlaylist() {
    this.playlistTarget.classList.toggle("opened");
  }

  togglePlaylistMobile() {
    this.playlistMobileTarget.classList.toggle("opened");
  }

  unload() {
    var self = this;

    self.playlist.forEach((sound) => {
      if(sound.howl) sound.howl.unload();
    });
    self.playlist = [];
  }
}
